let Offers;
let Offer_Weekend_WM;

Offer_Weekend_WM = {
    link: "https://weedoit.digital/2024/09/06/decouvrez-nos-offres-speciales-sur-whatsapp-et-realisez-des-economies-sur-vos-leads/?utm_source=plateforme&utm_medium=banner&utm_campaign=offre-we-06092024",
    img: "img/WM-SPECIALES-OFFERS-10.png",
    btnText: "EN SAVOIR PLUS",
    color: "red"
};

Offers = [
    {
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7241735971497492480",
        img: "img/new-quiz-plateforme-146.gif",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/03/01/actudata-integre-le-comparateur-mon-gustave-sur-son-panel-sante/?utm_source=plateforme&utm_medium=banner&utm_campaign=smatis-partenaire-MG",
        img: "img/smatis-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/02/06/gofriday-integre-le-comparateur-mon-gustave-sur-son-panel-assurance-mrh/?utm_source=Plateforme&utm_medium=Banner&utm_campaign=partenariat-gofriday",
        img: "img/boostez-vos-ventes-avec-weedo-coreg.png",
        btnText: "EN SAVOIR PLUS",
        color: "red"
    },
    {
        link: "https://weedoit.digital/2024/02/06/gofriday-integre-le-comparateur-mon-gustave-sur-son-panel-assurance-mrh/?utm_source=Plateforme&utm_medium=Banner&utm_campaign=partenariat-gofriday",
        img: "img/friday-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/02/02/selfepargne-integre-le-comparateur-mon-gustave-sur-son-panel-assurance-emprunteur-3/",
        img: "img/allianz-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    /* {
        link: "https://weedoit.digital/2024/01/25/meilleurs-voeux-2022-2/?utm_source=plateforme&utm_medium=banner&utm_campaign=voeux_2024",
        img: "img/meilleurs-voeux.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    }, */
    {
        link: "https://weedoit.digital/2024/01/03/selfepargne-integre-le-comparateur-mon-gustave-sur-son-panel-assurance-emprunteur-2/?utm_source=Plateforme&utm_medium=Banner&utm_campaign=ramify-integre-le-panel-per",
        img: "img/ramify-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2023/10/25/selfepargne-integre-le-comparateur-mon-gustave-sur-son-panel-assurance-emprunteur/?utm_source=plateforme&utm_medium=banner&utm_campaign=partenariat-MG-Selfepargne",
        img: "img/selfepargne-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2023/09/20/goodflair-integre-le-comparateur-mon-gustave-sur-son-panel-animaux/?utm_source=plateforme&utm_medium=banner&utm_campaign=partenariat_MG_Goodflair_animaux",
        img: "img/goodflair-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2023/07/10/empruntis-integre-le-comparateur-mon-gustave-sur-son-panel-assurance-emprunteur/?utm_source=plateforme&utm_medium=banner&utm_campaign=partenariat-MG-empruntis",
        img: "img/empruntis-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "list program",
        img: "",
        btnText: "",
        color: ""
    },
    {
        link: "https://weedoit.digital/2023/07/05/younited-credit-integre-le-comparateur-mon-gustave-sur-son-panel-credit-conso/?utm_source=plateforme&utm_medium=banner&utm_campaign=partenariat-MG-Younited-credit",
        img: "img/younited-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2023/06/30/weedo-market-lance-sa-nouvelle-gamme-duo/?utm_source=plateforme&utm_medium=banner&utm_campaign=offre-duo_WM",
        img: "img/gamme-duo-weedo-market.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2023/05/22/cofidis-integre-le-comparateur-mon-gustave-sur-son-panel-credit-conso/?utm_source=plateforme&utm_medium=banner&utm_campaign=cofidis-partenaire-MG",
        img: "img/cofidis-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    /* {
        link: "https://weedoit.digital/2023/05/12/replay-webinar-%e2%96%b6%ef%b8%8f-les-impacts-et-les-opportunites-du-decret-sur-le-demarchage-telephonique/?utm_source=plateforme&utm_medium=banner&utm_campaign=replay-webinar-11mai2023",
        img: "img/Replay_Webinar.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    }, */
    {
        link: "https://weedoit.digital/2023/03/14/klian-integre-le-comparateur-mon-gustave-sur-son-panel-habitation/?utm_source=plateforme&utm_medium=banner&utm_campaign=partenariat_MG_Klian",
        img: "img/klian-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://www.mongustave.fr/blog/assurance-sante/mgc-integre-le-comparateur-mon-gustave-sur-son-panel-sante/?utm_source=plateforme&utm_medium=banner&utm_campaign=partenariat_MG_MGC",
        img: "img/mgc-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    /* {
        link: "https://weedoit.digital/2023/03/03/avez-vous-suffisamment-anticipe-le-nouveau-decret-sur-le-demarchage-telephonique/?utm_source=plateforme&utm_medium=banner&utm_campaign=decret-1-mars-2023",
        img: "img/demarchage-telephonique.jpg",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    }, */
    {
        link: "https://weedoit.digital/2023/02/17/sharelock-integre-le-comparateur-mon-gustave-sur-son-panel-assurance-velo/?utm_source=Plateforme&utm_medium=banner&utm_campaign=Partenariat-MG-Sharelock-velo",
        img: "img/sharelock-partenariat-Mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2022/09/09/assurance-emprunteur-une-opportunite-a-saisir-avec-la-loi-lemoine/?utm_source=NP&utm_medium=banner&utm_campaign=Loi-Lemoine",
        img: "img/assurance_emprunteur_mongustave.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    /* {
        link: "https://weedoit.digital/2022/08/23/weedo-market-partenaire-de-bubblein/?utm_source=NP&utm_medium=banner&utm_campaign=partenariat-bubble-in&utm_id=partenariat+bubbleIn",
        img: "img/BubbleIn-partenariat-NP.png",
        btnText: "EN SAVOIR PLUS",
        color: "red"
    }, */
    /* {
        link: "https://weedoit.digital/2022/08/01/barometre-animaux-mon-gustave/?utm_source=NP&utm_medium=banner&utm_campaign=barom%C3%A8tre-animaux-2022&utm_id=Barom%C3%A8tre+sant%C3%A9+animaux",
        img: "img/Barometre_2022.png",
        btnText: "EN SAVOIR PLUS",
        color: "red"
    }, */
    {
        link: "https://weedoit.digital/2022/02/04/decouvrez-nos-offres-de-partenariats/?utm_source=NP&utm_medium=banner&utm_campaign=Partenariats-WM",
        img: "img/offres-partenariats.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2022/01/25/retrouvez-weedo-market-sur-whatsapp/?utm_source=NP&utm_medium=banner&utm_campaign=WM-WhatsApp",
        img: "img/bannier_Whatsapp.jpg",
        btnText: "EN SAVOIR PLUS",
        color: "red"
    },
    {
        link: "https://weedoit.digital/2020/11/30/resiliation-infra-annuelle-une-opportunite-pour-collecter-des-leads-assurance/",
        img: "img/resiliation-infra.jpg",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://www.mongustave.fr/blog/assurance-animaux/lovys-integre-le-comparateur-mon-gustave-sur-son-panel-animaux/?utm_source=plateforme&utm_medium=banner&utm_campaign=lovys-partenaire-MG",
        img: "img/assurance_animaux_mongustave.jpg",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/07/31/vacances-ete-2024/?utm_source=plateforme&utm_medium=banner&utm_campaign=com-ete-2024",
        img: "img/vacances-ete.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/08/22/retrouvez-mon-gustave-le-17-et-18-septembre-2024-aux-journees-du-courtage-a-paris-2/?utm_source=plateforme&utm_medium=banner&utm_campaign=JDC2024",
        img: "img/journees-du-courtage.jpg",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://app.livestorm.co/p/3c840df5-6793-467e-a3cc-08f9fb52d831",
        img: "img/visuel-weedo-IT.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/09/04/pret-pour-une-bonne-rentree-2/?utm_source=plateforme&utm_medium=banner&utm_campaign=rentree-2024",
        img: "img/rentree_2024.jpg",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
    {
        link: "https://weedoit.digital/2024/09/16/go-friday-integre-le-comparateur-mon-gustave-sur-son-panel-assurance-mrh-2/?utm_source=plateforme&utm_medium=banner&utm_campaign=qivio_partenaire_MG",
        img: "img/go_friday.png",
        btnText: "EN SAVOIR PLUS",
        color: "blue"
    },
];


export {
    Offers,
    Offer_Weekend_WM
}
